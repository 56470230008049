<template lang="pug">
    .userlog-record-data
        .sometimes
            div(v-if="record.phone") <b>Телефон:</b> {{ record.phone }}
            div(v-if="record.sender_name") <b>Отправитель:</b> {{ record.sender_name }}
        .always
            div(v-if="record.ip") <b>IP:</b> {{ record.ip }}
            div(v-if="record.ua") <b>UA:</b> {{ record.ua }}
            div(v-if="record.uid") <b>UID:</b> {{ record.uid }}
</template>

<script>
export default {
    props: [
        'record'
    ],
}
</script>

